<template>
  <div>
    <div class="wrapper">
      <van-form @submit="onSubmit" style="height: 100%">
        <div class="head-tips">正在进行第三方支付</div>
        <div class="box">
          <div class="">
            <div class="title">
              待支付 ¥ <span>{{ (money - subForm.score) / 100 }}</span>
            </div>
            <div class="form-item">
              <div class="item-lable name">姓名</div>
              <div class="">{{ userinfo.id_name }}</div>
            </div>
            <div class="form-item">
              <div class="item-lable name">身份证号</div>
              <div class="">{{ userinfo.id_number }}</div>
            </div>
            <div class="form-box">
              <div class="bank">
                <div class="item-lable line">银行</div>
                <div class="">
                  <van-field
                    v-model="bnk_name"
                    is-link
                    readonly
                    right-icon="arrow-down"
                    class="field"
                    name="picker"
                    placeholder="请选择银行"
                    @click="showBnkList = true"
                  />
                  <van-popup v-model:show="showBnkList" position="bottom">
                    <van-picker
                      :columns="banklidt"
                      @confirm="onConfirm"
                      :columns-field-names="customFieldName"
                      @cancel="(showBnkList = false), (BankHint = subForm.bnk_id == '')"
                    />
                  </van-popup>
                </div>
              </div>
              <div v-if="BankHint" class="phone-warn"><span>请选择银行</span></div>
              <div class="">
                <div class="item-lable line">银行卡号</div>
                <div class="">
                  <van-field
                    v-model="subForm.acct_num"
                    class="field"
                    @blur="acctNumBlur"
                    placeholder="请输入银行借记卡号"
                  />
                </div>
              </div>
              <div v-if="acctHint" class="phone-warn"><span>请输入正确银行借记卡号</span></div>
              <div class="">
                <div class="item-lable line">预留手机号</div>
                <div class="">
                  <van-field
                    clearable
                    v-model="subForm.mobile"
                    class="field"
                    @blur="mobileBlur"
                    placeholder="请输入银行预留手机号"
                  />
                </div>
              </div>
              <div v-if="phoneHint" class="phone-warn"><span>请输入预留手机号</span></div>
              <div class="">
                <div class="item-lable line">验证码</div>
                <div class="form-item-yanz">
                  <van-field
                    center
                    clearable
                    class="field field-yanz"
                    placeholder="输入验证码"
                    v-model="verify_code"
                    @blur="verifyBlur"
                    :border="false"
                  />

                  <van-button
                    size="small"
                    class="sendverify"
                    type="default"
                    :disabled="complainSMS.disabled"
                    @click="clickSendSms"
                    hairline
                    >{{ complainSMS.text }}</van-button
                  >

                  <!-- <van-field
              v-model="subForm.acct_num"
              class="field field-yanz"
              placeholder="输入验证码"
            /> -->
                </div>
              </div>
              <div v-if="verifyHint" class="phone-warn"><span>请输入验证码</span></div>
              <p class="tips">注：请输入与实名认证匹配的银行卡信息</p>
            </div>
          </div>

          <div class="btn-bottom">
            <van-button
              @click="surePay"
              class="btn"
              :disabled="paydisabled"
              :class="paydisabled ? 'btn-gre' : ''"
              round
              block
              type="primary"
              native-type="submit"
            >
              确认支付
            </van-button>
            <!-- <div class="checked" @click="checked = !checked">
            <div :class="checked ? 'ck' : ''">
              <van-icon v-show="checked" name="success" />
            </div>
            记住信息，便于下次支付
          </div> -->
          </div>
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted, ref } from 'vue';
import { localStorageUtil, nftUtils } from '@/utils';
import { useRouter, useRoute } from 'vue-router';
import { payways, allinpay_cardpay, allinpay_verifycode, getpaystatus } from '@/service/order';
import { Toast } from 'vant';
import { useStore } from 'vuex';
export default {
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const state = reactive({
      showBnkList: false,
      banklidt: [],
      money: '', //金额
      // order_no: '',
      customFieldName: {
        text: 'bnk_name'
        //values: 'bnk_id'
      },
      complainSMS: {
        text: '获取验证码',
        disabled: true,
        isCountDown: false
      },
      bnk_name: '',
      subForm: {
        bnk_id: '', //'03040000',
        acct_num: '', // '1234551234567890987',
        order_no: '',
        mobile: '',
        score: 0
      },
      verify_code: '', //'123456', //短信验证码
      checked: true,
      BankHint: false, //是否选择银行
      acctHint: false, //银行卡号验证
      phoneHint: false, // 手机号
      verifyHint: false, //验证码
      paydisabled: true,
      userinfo: {},
      getVerify: false, // 是否有获取过验证码
      payStatus: 1, //1--正常支付，2--预付款
      verifyPost: false
    });
    onMounted(async () => {
      nftUtils.noPull();
      state.subForm.order_no = route.query.order;
      state.money = route.query.money;
      state.payStatus = route.query.payStatus || 1;
      state.subForm.score = route.query.score ? route.query.score : 0; //积分
      state.isLogin = nftUtils.isLogin();
      if (state.isLogin) {
        const { data } = await payways();

        state.banklidt = data.bnk_list;
        state.userinfo = data.user_acct_info;
        state.subForm.mobile = data.user_acct_info.mobile;
      }
    });
    const onConfirm = currentValue => {
      state.showBnkList = false;

      // state.complainSMS.disabled = state.mobileHintShow || state.complainSMS.isCountDown;
      //  state.submitDisabled = state.mobileHintShow || !state.haveErify || !state.checked;
      console.log(currentValue);
      state.subForm.bnk_id = currentValue.bnk_id;
      state.bnk_name = currentValue.bnk_name;
      state.BankHint = state.subForm.bnk_id == '';
      state.complainSMS.disabled = validation();
      state.paydisabled = validation(1);
    };
    const onSubmit = () => {};
    // 验证
    const validation = type => {
      let yanzhen =
        state.subForm.bnk_id == '' ||
        state.subForm.acct_num == '' ||
        !nftUtils.mobileTest(state.subForm.mobile);
      let bl = type ? yanzhen || state.verify_code == '' : yanzhen || state.complainSMS.isCountDown;
      if (bl) {
        return true;
      } else {
        return false;
      }
    };
    const mobileBlur = () => {
      state.phoneHint = !nftUtils.mobileTest(state.subForm.mobile);
      state.complainSMS.disabled = validation();
      state.paydisabled = validation(1);
      // state.complainSMS.disabled = state.mobileHintShow || state.complainSMS.isCountDown;
      // state.submitDisabled = state.mobileHintShow || !state.haveErify || !state.checked;
    };
    const acctNumBlur = () => {
      // let cardReg = '';
      state.subForm.acct_num == '' ? (state.acctHint = true) : (state.acctHint = false);
      state.complainSMS.disabled = validation();
      state.paydisabled = validation(1);
    };
    const verifyBlur = () => {
      state.verifyHint = state.verify_code == '' ? true : false;
      state.complainSMS.disabled = validation();
      state.paydisabled = validation(1);
    };
    // 获取验证码
    const clickSendSms = async () => {
      if (state.verifyPost) {
        return;
      }
      state.verifyPost = true;
      Toast.loading({
        message: '发送中',
        forbidClick: true,
        loadingType: 'spinner',
        duration: 0
      });
      const { status, message } = await allinpay_cardpay(state.subForm);
      state.verifyPost = false;
      Toast.clear();
      if (status == 0) {
        // 倒计时
        nftUtils.countDownFn(state.complainSMS);
        state.getVerify = true;
      } else {
        //  Toast({
        //   message: message,
        //   icon: 'cross'
        // });
        Toast(message);
      }
    };

    const surePay = async () => {
      if (!state.getVerify) {
        Toast('请先获取验证码');
        return;
      }
      Toast.loading({
        message: '正在支付中，请稍等',
        forbidClick: true,
        loadingType: 'spinner',
        duration: 0
      });
      const { status, message } = await allinpay_verifycode({
        verify_code: state.verify_code,
        order_no: state.subForm.order_no
      });
      if (status == 0) {
        pollingGetpaystatus();

        // router.push({ path: `/order`, replace: true });
      } else {
        Toast.clear();
        Toast(message);
      }
    };

    const pollingGetpaystatus = async () => {
      let timer = setInterval(
        () => {
          console.log(11);
          getpaystatus({ order_no: state.subForm.order_no }).then(res => {
            if (res.data) {
              clearInterval(timer);
              Toast.clear();
              state.payStatus == 1 && localStorageUtil.set('orderId', state.subForm.order_no);
              // router.push({ path: `/order`, replace: true });
              Toast('支付成功');
              setTimeout(() => {
                router.go(-1);
              }, 500);
            }
          });
        },
        1000

        // getpaystatus({ order_no: state.subForm.order_no }).then(res => {}),
      );
    };

    return {
      ...toRefs(state),
      onConfirm,
      onSubmit,
      clickSendSms,
      validation,
      mobileBlur,
      acctNumBlur,
      verifyBlur,
      surePay,
      pollingGetpaystatus
    };
  }
};
</script>
<style lang="scss">
.bank {
  .van-cell__right-icon {
    display: none;
  }
}
</style>

<style lang="scss" scoped>
@import '../styles/mixin.scss';
* {
  touch-action: auto !important;
  // touch-action: pan-y;
}
.head-tips {
  background: #f3f3f3;
  font-size: 10px;
  color: #999999;
  text-align: center;
  padding: 4px 0;
  position: absolute;
  width: 100vw;
  left: 0;
  top: 0;
}
.form-box {
  background: #ffffff;
  box-shadow: 0px 4px 4px 4px rgba(232, 232, 232, 0.25);
  border-radius: 4px 4px 4px 4px;
  padding: 7px 19px;
}
.form-item-yanz {
  display: flex;
}
.phone-warn {
  color: $redc;
  font-size: 12px;
  // margin-left: 80px;
  margin-top: 10px;
  height: 12px;
}
.checked {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;

  color: rgba(255, 255, 255, 0.7);
  font-size: 12px;
  div {
    width: 16px;
    height: 16px;
    background: #494359;
    border-radius: 4px 4px 4px 4px;
    margin-right: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
  }
}
.ck {
  color: #3cdbe8;
  border: 1px solid #3cdbe8;
}
.btn-bottom {
  padding-bottom: 12px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  background-color: #fff;
  .btn {
    width: 335px;
    height: 44px;
    background: #3874c7;
    border-radius: 4px 4px 4px 4px;
    // opacity: 1;
    color: #fff;
    font-size: 20px;
    font-weight: 500;
  }
  .btn-gre {
    // opacity: 0.7;
    background-color: #a9bcd6;
  }
}
.box {
  // display: flex;
  // flex-direction: column;
  // justify-content: space-between;
  // height: 100%;
  // background: #fff;
}
.tips {
  font-size: 12px;

  color: #666666;
  margin-top: 15px;
}
.sendverify {
  box-sizing: border-box;
  margin-left: 12px;
  width: 120px;
  height: 44px;
  font-size: 14px;
  --van-button-default-background-color: $bc;
  --van-button-plain-background-color: $bc;
  --van-button-default-border-color: $fc;
  --van-button-border-width: 1px;
  --van-button-default-color: $fc;
  --van-button-border-radius: 4px;
}
.verify {
  width: 171px;
  height: 44px;
  background-color: $primaryc;
}
.title {
  text-align: center;
  margin: 23px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  span {
    color: #3874c7;
    font-weight: 500;
    font-size: 30px;
  }
}
.wrapper {
  padding: 20px 16px;
  font-size: 14px;
  box-sizing: border-box;
  min-height: 120vh;
  background-color: #fff;
  color: #333;
}
.auth-name {
  font-size: 14px;
}
.item-lable {
  min-width: 80px;
}
.line {
  margin: 14px 0 7px 0;
}
.name {
  padding-left: 18px;
}
.form-item {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  min-height: 44px;
}
.field-yanz {
  width: 171px !important;
}

.field {
  // width: 255px;
  height: 44px;
  // background-color: $primaryc;
  background: #f3f3f3;
  border-radius: 4px;
  // left: 50%;
  // transform: translateX(-50%);
  // --van-field-label-color: #fff;
  // --van-field-input-text-color: #fff;
  // --van-field-placeholder-text-color: #6d6979;
  // --van-field-input-disabled-text-color: #fff;
  // --van-field-disabled-text-color: #fff;
  // --van-cell-border-color: $primaryc;
}
</style>
